import { Component, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import { Subscription, map, tap } from 'rxjs';
import {
	MultiDropDownElements,
	OPTION_LABEL,
	OPTION_VALUE,
	getChildrenFunctions,
	getEndPointMethod,
} from '../../../../constants/components/input-select.constants';
import {
	ChildCascade,
	ChildrenFunction,
	CustomMultiTag,
	CustomMultiTagObject,
	DropDownCascade,
	ExtendedSelectMultiOptions,
	InputSelectOptions,
	SelectMultiOptions,
} from '../../../../model/components/input-meta/input-select.model';
import { OptionMetaData } from '../../../../model/components/meta-data.model';
import { InputType } from '../../../../model/components/shared-models.model';
import { DataObject } from '../../../../model/template/template.model';
import { SharedApiService } from '../../../../services/shared/shared-api.service';
import { isEmptyArray } from '../../../../utils/general-functions';
import { AbstractInputMeta } from '../abstract-input-meta.class';
@UntilDestroy()
@Component({
	selector: 'lib-input-select',
	templateUrl: './input-select.component.html',
	styleUrls: ['./input-select.component.scss'],
})
export class InputSelectComponent extends AbstractInputMeta implements OnChanges, OnDestroy {
	public selectedCustomMultiTag: CustomMultiTagObject = {};
	public listedCustomMultiTag: CustomMultiTagObject = {};
	private subscriptions: Subscription[] = [];

	get options(): DataObject[] {
		const _options = this.metaData.options ?? [];
		return _options.filter(
			(option: any) => this.selectMetaOptions?.filterOptions?.(option) ?? true
		);
	}

	get prevButtonStyle() {
		return {
			'background-color': this.selectMetaOptions?.prevButton?.bgColor,
			color: this.selectMetaOptions?.prevButton?.color,
			border: '1px solid ' + this.selectMetaOptions?.prevButton?.bgColor,
		};
	}

	get afterButtonStyle() {
		return {
			'background-color': this.selectMetaOptions?.afterButton?.bgColor,
			color: this.selectMetaOptions?.afterButton?.color,
			border: '1px solid ' + this.selectMetaOptions?.afterButton?.bgColor,
		};
	}
	get selectMetaOptions(): InputSelectOptions<any> | undefined {
		return this.metaData.inputMetaOptions as InputSelectOptions<any>;
	}

	get listedTagsOptions(): SelectMultiOptions<any> | undefined {
		return this.selectMetaOptions?.multiOptionsTags?.listedTagsOptions;
	}

	get selectedTagsOptions(): ExtendedSelectMultiOptions<any> | undefined {
		return this.selectMetaOptions?.multiOptionsTags?.selectedTagsOptions;
	}

	get isMultiOptionsTags(): boolean {
		return !!this.selectMetaOptions?.multiOptionsTags;
	}

	get dropDownClasses(): { [klass: string]: boolean } {
		let klasses = {};
		if (this.selectMetaOptions) {
			const { afterButton, prevButton } = this.selectMetaOptions;
			klasses = {
				'lib-dropdown': !!afterButton || !!prevButton,
				'remove-right-border': !!afterButton,
				'remove-left-border': !!prevButton,
			};
		}
		return klasses;
	}

	get optionLabel(): string {
		return this.selectMetaOptions?.optionLabel ?? OPTION_LABEL;
	}

	get optionValue(): string {
		return this.selectMetaOptions?.optionValue ?? OPTION_VALUE;
	}

	get isMultiElement(): boolean {
		return MultiDropDownElements.includes(this.metaData.input ?? InputType.Unknown);
	}

	get keySelectedOptionValue(): string {
		return this.selectedTagsOptions?.optionValue ?? (this.optionValue || OPTION_VALUE);
	}

	get inputSelectMetaOptions(): InputSelectOptions<any> {
		return this.metaData.inputMetaOptions as InputSelectOptions<any>;
	}

	private dropDownMethods: { [type: string]: Function } = {
		[InputType.Lookup]: this.setLookupOptions.bind(this),
		[InputType.LookupMulti]: this.setLookupOptions.bind(this),
		[InputType.Endpoint]: this.setEndPointOptions.bind(this),
		[InputType.EndpointMulti]: this.setEndPointOptions.bind(this),
	};

	constructor(private sharedApiService: SharedApiService) {
		super();
	}

	protected override abstractInit(): void {
		const { input } = this.metaData;
		if (input) {
			const dropDownMethod = this.dropDownMethods[input];
			if (dropDownMethod) dropDownMethod();
			if (this.isMultiElement && this.listedTagsOptions)
				this.listedCustomMultiTag = this.getCustomMultiTagObject(
					this.options,
					this.listedTagsOptions
				);
		}
	}

	public override destroyObservables(): void {
		this.selectMetaOptions?.cascade?.forEach((_cascade: DropDownCascade) =>
			_cascade.options$?.complete()
		);
	}
	public override onChangesMode(): void {}

	public ngOnChanges(changes: SimpleChanges): void {
		const { metaData } = changes;
		if (metaData) {
			this.setCascadeMode();
			this.setDefaultValueOption();
		}
	}

	// public ngOnDestroy(): void {
	//     // this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
	// }

	public deselectedMulti(option: any): void {
		const isObject = typeof option === 'object';
		const value = isObject ? option[this.keySelectedOptionValue] : option;
		const selectedOptions = this.formControl.value as [];
		const reSelectedOptions = selectedOptions.filter((optionValue: any) => {
			const objectValue = optionValue[this.keySelectedOptionValue] || optionValue;
			return objectValue !== value;
		});
		this.formControl.patchValue(reSelectedOptions);
	}

	public onChangeMulti(multiSelectEvent: MultiSelectChangeEvent): void {
		if (this.selectedTagsOptions)
			this.selectedCustomMultiTag = this.getCustomMultiTagObject(
				multiSelectEvent.value,
				this.selectedTagsOptions
			);
	}

	private setDefaultValueOption(): void {
		if (
			!MultiDropDownElements.includes(this.metaData.input || InputType.Unknown) &&
			this.selectMetaOptions &&
			!this.inputSelectMetaOptions?.disableDefaultValue
		) {
			const { defaultValue, defaultFirstValue } = this.selectMetaOptions;
			if (defaultValue) {
				const optionKey = 'key' in defaultValue ? defaultValue.key ?? '' : 'value';
				const option = this.metaData.options?.find(
					(_option: DataObject) => _option[optionKey] === defaultValue.value
				);
				if (option) return this.formControl.patchValue(option[optionKey]);
			}
			if (defaultFirstValue) this.control.patchValue(this.options[0]?.['value']);
		}
	}

	private parentCascade(cascade: DropDownCascade[]): void {
		const cascadeField = cascade.find(
			(cascade: DropDownCascade) => cascade.field === this.metaData.field
		);
		if (cascadeField) {
			const childrenFunctions = getChildrenFunctions(cascadeField.children);
			this.control.valueChanges
				.pipe(
					untilDestroyed(this),
					tap((value: any) => {
						childrenFunctions.forEach((childFunction: ChildrenFunction) => {
							const response$ = childFunction(value);
							const subscription = response$.subscribe((options: DataObject[]) =>
								cascadeField.options$?.next(options)
							);
							this.subscriptions.push(subscription);
						});
					})
				)
				.subscribe();
		}
	}

	private childrenCascade(cascade: DropDownCascade[]): void {
		const cascadeChild = cascade.filter((_cascade: DropDownCascade) =>
			_cascade.children.some((child: ChildCascade) => child.field === this.metaData.field)
		);
		cascadeChild.forEach((cas: DropDownCascade) => {
			const subscription = cas.options$
				?.asObservable()
				.pipe(tap((ops: DataObject[]) => (this.metaData.options = ops as OptionMetaData[])))
				.subscribe((ops: DataObject[]) => {
					const hidden = isEmptyArray(ops) ? cas.hiddenEmpty : false;
					this.inputHidden(hidden);
					if (this.isMultiElement && this.listedTagsOptions)
						this.listedCustomMultiTag = this.getCustomMultiTagObject(
							ops,
							this.listedTagsOptions
						);
				});
			if (subscription) this.subscriptions.push(subscription);
		});
	}

	private setCascadeMode(): void {
		const cascade = this.selectMetaOptions?.cascade;
		if (cascade) {
			this.childrenCascade(cascade);
			this.parentCascade(cascade);
		}
	}

	private getCustomMultiTagObject(
		options: any,
		selectMultiOptions: ExtendedSelectMultiOptions<any> | SelectMultiOptions<any>
	): CustomMultiTagObject {
		const customFn = selectMultiOptions?.customOptionText;
		if (customFn) {
			return this.getMultiTagObject(options, customFn);
		}
		return {};
	}

	private getMultiTagObject(
		options: any,
		customFn: (option: any) => CustomMultiTag
	): CustomMultiTagObject {
		return options.reduce((customMultiTagObject: any, opt: any) => {
			const key = opt[this.keySelectedOptionValue] || opt;
			return { ...customMultiTagObject, [key]: customFn(opt) };
		}, {});
	}

	private setLookupOptions(): void {
		const { lookupType } = this.metaData;
		const params = {
			type: lookupType,
		};
		this.sharedApiService
			.getDataEP$<DataObject[]>('lookups', params)
			.pipe(
				untilDestroyed(this),
				tap(() => this.onLoad.emit(this.metaData))
			)
			.subscribe((options: DataObject[]) => {
				this.assignOptions(options);
				this.setDefaultValueOption();
			});
	}

	private setEndPointOptions(): void {
		const { endPoint } = this.metaData;
		if (endPoint) {
			const methodToInvoke = getEndPointMethod(endPoint, this.sharedApiService);
			const keyObject = this.selectMetaOptions?.endPointKeyObject ?? 'data';
			methodToInvoke(endPoint)
				.pipe(
					untilDestroyed(this),
					map((response: any) =>
						Array.isArray(response) ? response : response?.[keyObject]
					),
					tap(() => this.onLoad.emit(this.metaData))
				)
				.subscribe((options: DataObject[]) => {
					this.assignOptions(options);
					this.setDefaultValueOption();
				});
		}
	}

	private assignOptions(_options: DataObject[]) {
		this.metaData.options = _options as OptionMetaData[];
		if (this.isMultiElement && this.listedTagsOptions)
			this.listedCustomMultiTag = this.getCustomMultiTagObject(
				_options,
				this.listedTagsOptions
			);
	}
}

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    TemplateRef,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MetaDataExtended, MetaDataOptions } from '../../../model/components/meta-data.model';
import { InputMode } from '../../../model/components/shared-models.model';
import { DataObject } from '../../../model/template/template.model';
import { AbstractInputMeta } from './abstract-input-meta.class';
import { createComponent } from './input-meta.constants';
import { InputTypeParams } from './input-meta.model';

@UntilDestroy()
@Component({
    selector: 'lib-input-meta',
    templateUrl: './input-meta.component.html',
    styleUrls: ['./input-meta.component.scss'],
})
export class InputMetaComponent implements OnChanges, OnDestroy, AfterViewInit {
    @Input({ required: true }) params!: InputTypeParams<any>;
    @Input({ required: true }) inputType!: Type<any>;
    @Input() metaDataOptions?: MetaDataOptions<any>;
    @Input() disableDefaultValue?: boolean;

    @Output() onCreated: EventEmitter<AbstractInputMeta> = new EventEmitter();

    @ViewChild('groupWrapper', { static: true }) groupWrapper!: TemplateRef<any>;
    @ViewChild('simpleWrapper', { static: true }) simpleWrapper!: TemplateRef<any>;
    @ViewChild('templateContainer', { read: ViewContainerRef, static: false }) private templateContainer!: ViewContainerRef;

    public abstractInputMeta!: AbstractInputMeta;
    public templateRef!: TemplateRef<any>;

    get readValue(): any {
        return `${this.params.metaData.options}#${this.control.value}`;
    }
    get mode(): InputMode {
        return this.params.mode;
    }

    get control(): AbstractControl {
        return this.params.control;
    }

    get metaData(): MetaDataExtended<any> {
        return this.params.metaData;
    }

    get reloadMasonry(): Function {
        return this.params.reloadMasonry;
    }

    get isReadMode(): boolean {
        return this.mode !== InputMode.Write || !this.metaData.editable;
    }

    get topErrorKlass(): DataObject {
        const show = this.metaData.metaDataStyleOptions?.errorMessageInTop;
        return { 'error-message-in-top': show };
    }

    get smallErrorReverseKlass(): DataObject {
        const show = this.metaData.metaDataStyleOptions?.errorMessageInTop;
        return { 'small-error-reverse': show };
    }

    get template(): TemplateRef<any> | null {
        if (this.metaData.metaDataStyleOptions?.wrapperType === 'simple') return this.simpleWrapper;
        return this.groupWrapper;
    }

    get hiddenLabel(): boolean {
        return this.mode === InputMode.Write && !!this.metaData.metaDataStyleOptions?.hiddenLabel;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes['params']?.isFirstChange() && this.abstractInputMeta) this.abstractInputMeta.setMode(this.params.mode);
    }

    public ngAfterViewInit(): void {
        setTimeout(this.createInput.bind(this), 500);
    }

    public ngOnDestroy(): void {
        this.onCreated.complete();
    }

    public createInput(): void {
        if (this.inputType) {
            const { instance } = createComponent(this.inputType, this.templateContainer, this.params, this.metaDataOptions);
            this.abstractInputMeta = instance;
            return this.setCreatedEventEmitter(instance);
        }
        throw new Error(`No existe componente registrado para '${this.params.metaData.field}' [${this.params.metaData.input}]`);
    }

    public destroyInputObservables(): void {
        this.abstractInputMeta.destroyObservables();
    }

    private setCreatedEventEmitter(instance: AbstractInputMeta): void {
        instance.onCreated.pipe(untilDestroyed(this)).subscribe((abstractInput: AbstractInputMeta) => this.onCreated.emit(abstractInput));
    }
}

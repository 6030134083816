import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { DashboardLibModule } from 'dashboard-lib';
import { GlobalUserBalanceComponent } from './components/global-user-balance/global-user-balance.component';
import { HomeItemMenuComponent } from './components/home-item-menu/home-item-menu.component';
import { HomeItemsMenuComponent } from './components/home-items-menu/home-items-menu.component';
import { HomeComponent } from './components/home/home.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { SharedTitleComponent } from './components/shared-title/shared-title.component';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';

@NgModule({
	declarations: [
		HomeComponent,
		HomeItemMenuComponent,
		HomeItemsMenuComponent,
		SharedTitleComponent,
		PhoneNumberPipe,
		ProgressSpinnerComponent,
		GlobalUserBalanceComponent,
	],
	imports: [CommonModule, RouterModule, DashboardLibModule],
	exports: [
		SharedTitleComponent,
		HomeComponent,
		PhoneNumberPipe,
		ProgressSpinnerComponent,
		GlobalUserBalanceComponent,
	],
})
export class SharedModule {}

import { Type } from '@angular/core';
import { InputAreaComponent } from '../../components/forms/input-meta/input-area/input-area.component';
import { InputBoolComponent } from '../../components/forms/input-meta/input-bool/input-bool.component';
import { InputComplexComponent } from '../../components/forms/input-meta/input-complex/input-complex.component';
import { InputSelectComponent } from '../../components/forms/input-meta/input-select/input-select.component';
import { InputTableComponent } from '../../components/forms/input-meta/input-table/input-table.component';
import { InputTextComponent } from '../../components/forms/input-meta/input-text/input-text.component';
import { InputUnknownComponent } from '../../components/forms/input-meta/input-unknown/input-unknown.component';
import { InputComplexOptions, InputComplexTypeOptions } from '../../model/components/input-meta/input-complex.model';
import { ComplexType, InputType } from '../../model/components/shared-models.model';
import { InputPasswordComponent } from '../../components/forms/input-meta/input-password/input-password.component';

export const InputTypeList: Record<string, Type<any>> = {
    [InputType.Text]: InputTextComponent,
    [InputType.Check]: InputBoolComponent,
    [InputType.Select]: InputSelectComponent,
    [InputType.SelectMulti]: InputSelectComponent,
    [InputType.Endpoint]: InputSelectComponent,
    [InputType.EndpointMulti]: InputSelectComponent,
    [InputType.Lookup]: InputSelectComponent,
    [InputType.LookupMulti]: InputSelectComponent,
    [InputType.Textarea]: InputAreaComponent,
    [InputType.Unknown]: InputUnknownComponent,
    //TODO Refactor complex
    [InputType.Complex]: InputComplexComponent,
    [InputType.Password]: InputPasswordComponent,
};

const ComplexInputList: Record<string, Type<any>> = {
    [ComplexType.Table]: InputTableComponent,
    [ComplexType.Default]: InputUnknownComponent,
};

export const createComplexOptions: (complexType: ComplexType, componentOptions?: InputComplexTypeOptions<any>) => InputComplexOptions<any> = (
    complexType: ComplexType,
    componentOptions?: InputComplexTypeOptions<any>
) => ({ component: ComplexInputList[complexType], type: complexType, componentOptions });

import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationService } from 'dashboard-lib';
import { USERS_ENTITY_NAME } from 'src/app/users/constants/users.constants';
import { UserBalance } from 'src/app/users/interfaces/users.interfaces';
import { UsersService } from 'src/app/users/services/users.service';

@UntilDestroy()
@Component({
	selector: 'shared-global-user-balance',
	templateUrl: './global-user-balance.component.html',
	styleUrls: ['./global-user-balance.component.scss'],
})
export class GlobalUserBalanceComponent implements OnInit {
	public userBalance: string = '';
	public loading: boolean = true;

	constructor(
		private _usersSevice: UsersService,
		private _notificationService: NotificationService
	) {}

	public ngOnInit(): void {
		// setTimeout(() => this.getUserBalance(), 1000);
	}

	public getUserBalance() {
		this._usersSevice
			.getUserBalance()
			.pipe(untilDestroyed(this))
			.subscribe({
				next: (userBalance: UserBalance) => {
					this.userBalance = userBalance.amountBalance.toString();
					this.loading = false;
				},
				error: () => {
					this._notificationService.onError(
						'Error al obtener el saldo del usuario',
						USERS_ENTITY_NAME
					);
					this.loading = false;
				},
			});
	}
}

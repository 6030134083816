export const environment = {
	production: false,
	appVersion: `${require('../../package.json').version}`,
	clientId: 'j98tcku42bsbpnt7nd54ovl0u',
	scope: 'email openid profile',
	uriCallback: 'https://easypay.test.logialabs.com/login/callback',
	oauthUrl: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_oGBPLOT3q',
	logoutUrl: 'https://easypay.test.logialabs.com/logout',
	apiMetaDataUrl: 'https://dev.logiasystems.com',
	revocationEndpoint: 'https://api.easypay.test.logialabs.com/auth/revoke',
	apiKey: '',
	requireHttps: true,
	//apiUrl: 'http://localhost:5081', // TODO: Remove this local apiURL
	apiUrl: 'https://api.easypay.test.logialabs.com',
	tokenEndpoint: 'https://api.easypay.test.logialabs.com/auth',
};

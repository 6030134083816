import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';
import { getErrorMessage } from '../../../constants/components/panel.constants';
import { ConditionMetaData, MetaDataExtended, ValidationMetaData } from '../../../model/components/meta-data.model';
import { InputMode } from '../../../model/components/shared-models.model';
import { isEmptyArray } from '../../../utils/general-functions';

@UntilDestroy()
@Directive()
export abstract class AbstractInputMeta implements OnInit, OnDestroy {
    @Input({ required: true }) metaData!: MetaDataExtended<any>;
    @Input({ required: true }) control!: AbstractControl;
    @Input({ required: true }) mode!: InputMode;
    @Input({ required: true }) reloadMasonry!: Function;
    @Input({ required: true }) simpleWrapper: boolean = false;

    @Output() onLoad: EventEmitter<MetaDataExtended<any>> = new EventEmitter();
    @Output() onCreated: EventEmitter<AbstractInputMeta> = new EventEmitter();

    public errorMessages: string[] = [];
    private controlMode: FormControl = new FormControl();

    get showError(): boolean {
        return !this.control.valid && this.mode === InputMode.Write;
    }

    get isReadMode(): boolean {
        return this.mode !== InputMode.Write || !this.metaData.editable;
    }

    get formControl(): FormControl {
        return this.control as FormControl;
    }

    get formArray(): FormArray {
        return this.control as FormArray;
    }

    get formGroup(): FormGroup {
        return this.control as FormGroup;
    }

    public ngOnInit(): void {
        this.abstractInit();
        this.clearErrorsWhenHidden();
        this.syncValidations();
        this.onCreated.emit(this);
        this.controlMode.valueChanges.pipe(untilDestroyed(this)).subscribe(this.onChangesMode.bind(this));
    }

    public ngOnDestroy(): void {
        this.onCreated.complete();
        this.onLoad.complete();
    }

    public inputHidden(hidden: boolean): void {
        this.metaData.hidden = hidden;
    }

    public setMode(mode: InputMode): void {
        this.mode = mode;
        this.controlMode.setValue(mode);
    }

    protected abstract abstractInit(): void;
    public abstract destroyObservables(): void;
    public abstract onChangesMode(): void;

    private clearErrorsWhenHidden(): void {
        if (this.metaData.hidden) this.control.setErrors(null);
    }

    /**
     * @description set the validations to manage the error messages
     */
    private syncValidations(): void {
        this.control.valueChanges.pipe(untilDestroyed(this), tap(this.reloadLayout.bind(this))).subscribe(this.emitValidations.bind(this));
        this.control.updateValueAndValidity();
    }

    /**
     * @description it's called when it is necessary to reloadLayout  for Masonry proposes and
     */
    private reloadLayout(): void {
        if (this.control.invalid && this.reloadMasonry) this.reloadMasonry();
    }

    /**
     * @description this method works for dispatch the error message
     */
    private emitValidations(): void {
        const validationConditions = this.metaData.conditions?.flatMap((condition: ConditionMetaData) => condition.validations) || [];
        const validations = isEmptyArray(validationConditions) ? this.metaData.validations || [] : validationConditions;
        const errorMessagesMethod = this.control instanceof FormGroup ? this.setErrorsGroup.bind(this) : this.setErrors.bind(this);
        errorMessagesMethod(validations);
    }

    /**
     *
     * @param validations
     * @description set to errorMessages param the errors of the control
     */
    private setErrors(validations: ValidationMetaData[]): void {
        this.errorMessages = getErrorMessage(this.control.errors, validations);
    }

    /**
     *
     * @param validations
     * @description set to errorMessages param the errors of the form group
     */
    private setErrorsGroup(validations: ValidationMetaData[]): void {
        let errors: string[] = [];
        if (this.control instanceof FormGroup) {
            const controls = this.control.controls;
            Object.keys(controls).forEach((key: string) => {
                const control = controls[key];
                const errorMessages = getErrorMessage(control.errors, validations);
                errors = [...errors, ...errorMessages];
            });
        }
        this.errorMessages = errors;
    }
}

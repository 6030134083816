import { Component, OnInit } from '@angular/core';
import { AbstractInputMeta } from '../abstract-input-meta.class';

@Component({
    selector: 'lib-input-password',
    templateUrl: './input-password.component.html',
    styleUrls: ['./input-password.component.scss'],
})
export class InputPasswordComponent extends AbstractInputMeta implements OnInit {
    public override ngOnInit(): void {
        super.ngOnInit();
    }

    protected override abstractInit(): void {}
    public override destroyObservables(): void {}
    public override onChangesMode(): void {}
}

export enum UserStatus {
	UNKNOWN = 'UNKNOWN',
	Active = 'Active',
	Inactive = 'Inactive',
}

export enum UserStatusTranslation {
	UNKNOWN = 'Desconocido',
	Active = 'Activo',
	Inactive = 'Inactivo',
}

export enum UserRoleCreationSource {
	UNKNOWN = 'UNKNOWN',
	MANUAL = 'MANUAL',
	APP = 'APP',
}

export enum Record {
	UNKNOWN = 'UNKNOWN',
	ROL = 'ROL',
}

export enum UserRole {
	Unknown = 'UNKNOWN',
	Admin = 'ADMIN',
	Admin_recargas = 'ADMIN_RECARGAS',
	Agente_ventas = 'AGENTE_VENTAS',
	Almacen = 'ALMACEN',
	Cliente = 'CLIENTE',
	Distribuidor_saldo = 'DISTRIBUIDOR_SALDO',
	Distribuidor_sims = 'DISTRIBUIDOR_SIMS',
	Gestor = 'GESTOR',
	Gestor_saldo = 'GESTOR_SALDO',
	Hijo = 'HIJO',
	Super_admin = 'SUPER_ADMIN',
}

export enum UserRoleName {
	UNKNOWN = 'Desconocido',
	ADMIN = 'Administrador',
	ADMIN_RECARGAS = 'Administrador',
	AGENTE_VENTAS = 'Agente de ventas',
	ALMACEN = 'Almacen',
	CLIENTE = 'Cliente',
	DISTRIBUIDOR_SALDO = 'Distribuidor de saldo',
	DISTRIBUIDOR_SIMS = 'Distribuidor de sims',
	GESTOR = 'Gestor',
	GESTOR_SALDO = 'Gestor de saldo',
	HIJO = 'Hijo',
	SUPER_ADMIN = 'Super administrador',
}

export enum UserEndpoint {
	Users = 'users',
	Permissions = 'permissions',
	Disable = 'disable',
	Enable = 'enable',
	Delete = 'delete',
	Contracts = 'contracts',
	Balance = 'balance',
	Update = 'update',
}

export enum AppOrigin {
	Unknown = 'UNKNOWN',
	Mobile = 'MOBILE',
	Web = 'WEB',
}

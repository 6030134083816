import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { NotificationService } from 'dashboard-lib';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
	constructor(private notificationService: NotificationService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			catchError((errorResponse: HttpErrorResponse) => {
				const { error } = errorResponse;
				let errorMessage = extractErrorMessage(error.message);
				this.notificationService.onError(errorMessage, 'Error');
				return throwError(() => new Error(error?.message));
			})
		);
	}
}
export const PROVIDE_API_RESPONSE: Provider = {
	provide: HTTP_INTERCEPTORS,
	useClass: ApiInterceptor,
	multi: true,
};

export function extractErrorMessage(errorMessage: string): string {
	const regex = /\[(.*?)\]/;
	const match = regex.exec(errorMessage);
	if (match) return match[1];
	return errorMessage;
}

import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { LoginConfig } from '../../../model/login/login.model';
import { ConfigService } from '../../../services/template/config.service';

@Component({
    selector: 'lib-logout',
    styleUrls: ['logout.component.scss'],
    templateUrl: 'logout.component.html',
})
export class LogoutComponent implements OnInit {
    public messageLogout: string = 'Iniciar sesión en APP';
    private loginConfig!: LoginConfig;

    constructor(_config: ConfigService, private oauthServiceOIDC: OAuthService) {
        const { messageLogout } = _config.navigationData;
        if (_config.loginConfig) this.loginConfig = _config.loginConfig;
        if (messageLogout) {
            this.messageLogout = messageLogout;
        }
    }

    ngOnInit() {
        this.oauthServiceOIDC.logOut({
            client_id: this.loginConfig.clientId,
            logout_uri: this.loginConfig.logoutUrl,
        });
    }
}

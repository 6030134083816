import { KeyValue } from 'src/app/shared/interfaces/shared.interfaces';
import { UserRole, UserRoleName } from '../enums/users.enums';

export const USERS_ENTITY_NAME = 'Usuarios';
export const RELATION_TYPE_QUERY_PARAM = 'relationType';

export const META_PANEL_CREATE_USER_CONFIG_NAME = 'pnl-create-users';
export const META_PANEL_VIEW_USER_CONFIG_NAME = 'pnl-view-users';
export const USER_NAME_CONTROL_NAME = 'name';
export const USER_ID_CONTROL_NAME = 'id';
export const USER_LAST_NAME_CONTROL_NAME = 'lastName';
export const USER_EMAIL_CONTROL_NAME = 'email';
export const USER_ROLE_CONTROL_NAME = 'userRole';
export const USER_STATUS_CONTROL_NAME = 'status';
export const USER_CLIENT_SELECT_CONTROL_NAME = 'userClient';
export const USER_CREATED_AT_CONTROL_NAME = 'createdAt';
export const USER_CREATED_BY_CONTROL_NAME = 'createdBy';
export const USER_UPDATED_AT_CONTROL_NAME = 'updatedAt';
export const USER_UPDATED_BY_CONTROL_NAME = 'updatedBy';

export const KEY_VALUE_ROLE_SUPER_ADMIN: KeyValue = {
	key: UserRole.Super_admin,
	value: UserRoleName.SUPER_ADMIN,
};

export const KEY_VALUE_ROLE_ADMIN: KeyValue = {
	key: UserRole.Admin,
	value: UserRoleName.ADMIN,
};

export const KEY_VALUE_ROLE_CLIENT: KeyValue = {
	key: UserRole.Cliente,
	value: UserRoleName.CLIENTE,
};

export const KEY_VALUE_ROLE_GESTOR: KeyValue = {
	key: UserRole.Gestor,
	value: UserRoleName.GESTOR,
};

export const KEY_VALUE_ROLE_HIJO: KeyValue = {
	key: UserRole.Hijo,
	value: UserRoleName.HIJO,
};

export const USERS_TO_ADD_WHEN_ADMIN: KeyValue[] = [
	KEY_VALUE_ROLE_CLIENT,
	KEY_VALUE_ROLE_GESTOR,
	KEY_VALUE_ROLE_HIJO,
];

export const USERS_TO_ADD_WHEN_SUPER_ADMIN: KeyValue[] = [
	...USERS_TO_ADD_WHEN_ADMIN,
	KEY_VALUE_ROLE_ADMIN,
];

export const USERS_TO_ADD_WHEN_HIJO: KeyValue[] = [];

export const USERS_TO_ADD_WHEN_GESTOR: KeyValue[] = [];

export const USERS_TO_ADD_WHEN_CLIENTE: KeyValue[] = [KEY_VALUE_ROLE_HIJO];

import { ConfigurationModel, NavigationType } from 'dashboard-lib';
import { PrimeIcons } from 'primeng/api';
import { environment } from 'src/environments/environment';

export const TemplateConfig: ConfigurationModel = {
	apiUrl: environment.apiUrl,
	apiKey: environment.apiKey,
	apiMetaDataUrl: environment.apiMetaDataUrl,
	navigation: {
		icon: PrimeIcons.COG,
		title: 'EasyPay',
		type: NavigationType.Top,
		toolbar: [],
		menus: [
			{
				label: 'Menu',
				icon: PrimeIcons.TH_LARGE,
				tooltip: 'Menu',
				routerLink: 'home',
			},
			{
				label: 'Usuarios',
				tooltip: 'Usuarios',
				icon: PrimeIcons.USER,
				routerLink: 'users',
			},
			{
				label: 'Contratos',
				tooltip: 'Contratos',
				icon: PrimeIcons.BRIEFCASE,
				routerLink: 'contracts',
			},
			{
				label: 'Servicios',
				tooltip: 'Servicios',
				icon: PrimeIcons.SERVER,
				routerLink: 'services',
			},
			{
				label: 'Recargas',
				tooltip: 'Recargas',
				icon: PrimeIcons.PHONE,
				routerLink: 'recharges',
			},
			{
				label: 'Saldo',
				tooltip: 'Saldo',
				icon: PrimeIcons.WALLET,
				routerLink: 'balances',
			},
		],
		urlProfile: 'users',
		messageLogin: 'Bienvenido a EasyPay Web',
		messageLogout: 'Inicia sesión en EasyPay Web',
	},
	login: {
		clientId: environment.clientId,
		scope: environment.scope,
		uriCallback: environment.uriCallback,
		oauthUrl: environment.oauthUrl,
		revocationEndpoint: environment.revocationEndpoint,
		logoutUrl: environment.logoutUrl,
		requireHttps: environment.requireHttps,
		tokenEndpoint: environment.tokenEndpoint,
	},
	version: `${environment.appVersion}`,
};

import { Component, Input } from '@angular/core';

@Component({
	selector: 'home-menu-item',
	templateUrl: './home-item-menu.component.html',
	styleUrls: ['./home-item-menu.component.scss'],
})
export class HomeItemMenuComponent {
	@Input({ required: true }) public menuTitle: string = '';
	@Input({ required: true }) public menuIcon: string = '';
	@Input({ required: true }) public menuColor: string = '';
}

import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DEFAULT_PROVIDERS, DashboardLibModule, TemplateConfigModule } from 'dashboard-lib';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TemplateConfig } from './configurations/template.config';
import { PROVIDE_API_RESPONSE } from './interceptors/api.interceptors';
import { PROVIDE_EASYPAY_HEADERS } from './interceptors/easy-pay.interceptors';
import { SharedModule } from './shared/shared.module';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		DashboardLibModule,
		TemplateConfigModule.forRoot(TemplateConfig),
		ReactiveFormsModule,
		HttpClientModule,
		SharedModule,
	],
	providers: [...DEFAULT_PROVIDERS, PROVIDE_EASYPAY_HEADERS, PROVIDE_API_RESPONSE],
	bootstrap: [AppComponent],
})
export class AppModule {}

export enum InputType {
    Text = 'text',
    Select = 'select',
    Lookup = 'lookup',
    Endpoint = 'endpoint',
    EndpointMulti = 'endpoint-multi',
    SelectMulti = 'select-multi',
    LookupMulti = 'lookup-multi',
    Check = 'check',
    Textarea = 'textarea',
    Date = 'date',
    DateTime = 'date-time',
    InputSelect = 'input-select',
    Unknown = 'unknown',
    Complex = 'complex',
    Password = 'password',
}

export enum DataType {
    Text = 'string',
    Array = 'array',
    Object = 'object',
    Number = 'number',
    Currency = 'currency',
    Boolean = 'boolean',
    Tel = 'tel',
    Date = 'date',
    Time = 'time',
    Percent = 'percent',
}

export enum ValidationType {
    Email = 'email',
    Max = 'max',
    Min = 'min',
    Required = 'required',
    MinLength = 'minlength',
    MaxLength = 'maxlength',
    Pattern = 'pattern',
}

export enum ComplexType {
    Default = 'default',
    Table = 'table',
    DataView = 'dataView',
    List = 'orderList',
    PickList = 'pickList',
    Tree = 'tree',
}

export enum ModeMetaData {
    New = 'new',
    Edit = 'modify',
    Table = 'table',
}

export enum InputMode {
    Read = 'read',
    Write = 'write',
}

export interface Country {
    iso2: string;
    iso3: string;
    name: string;
    cities: string[];
    states?: State[];
}

export interface State {
    name: string;
    stateCode: string;
}

export type CompareType = 'equals' | 'distinct' | 'contains';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './shared/components/home/home.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full',
	},
	{
		path: 'home',
		component: HomeComponent,
	},
	{
		path: 'recharges',
		loadChildren: () => import('./recharges/recharges.module').then(m => m.RechargesModule),
	},
	{
		path: 'users',
		loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
	},
	{
		path: 'contracts',
		loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule),
	},
	{
		path: 'services',
		loadChildren: () => import('./services/services.module').then(m => m.ServicesModule),
	},
	{
		path: 'balances',
		loadChildren: () => import('./balances/balances.module').then(m => m.BalancesModule),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IdDto } from 'src/app/shared/interfaces/shared.interfaces';
import { environment } from 'src/environments/environment';
import { RELATION_TYPE_QUERY_PARAM } from '../constants/users.constants';
import { UserEndpoint, UserRole } from '../enums/users.enums';
import {
	NewUser,
	PaginationResponse,
	UpdateUser,
	User,
	UserBalance,
} from '../interfaces/users.interfaces';

@Injectable({
	providedIn: 'root',
})
export class UsersService {
	constructor(private _httpClient: HttpClient) {}

	public getUsers(userRole?: UserRole): Observable<PaginationResponse> {
		const url = `${environment.apiUrl}/${UserEndpoint.Users}`;
		let params = new HttpParams();
		if (userRole) params = params.set(RELATION_TYPE_QUERY_PARAM, userRole);
		return this._httpClient.get<PaginationResponse>(url, {
			params,
		});
	}

	public getUserById(userId: string): Observable<User> {
		const url = `${environment.apiUrl}/${UserEndpoint.Users}/${userId}`;
		return this._httpClient.get<User>(url);
	}

	public getUserPermissions(userId: string): Observable<string[]> {
		const url = `${environment.apiUrl}/${UserEndpoint.Users}/${userId}/${UserEndpoint.Permissions}`;
		return this._httpClient.get<string[]>(url);
	}

	public createUser(newUser: NewUser): Observable<IdDto> {
		const url = `${environment.apiUrl}/${UserEndpoint.Users}`;
		return this._httpClient.post<IdDto>(url, newUser);
	}

	public activateDeactivateUser(userId: string, activateUser: boolean): Observable<void> {
		const activateDeactiveEndpoint = activateUser ? UserEndpoint.Enable : UserEndpoint.Disable;

		return this._httpClient.put<void>(
			`${environment.apiUrl}/${UserEndpoint.Users}/${userId}/${activateDeactiveEndpoint}`,
			undefined
		);
	}

	public updateUser(updateUser: UpdateUser): Observable<void> {
		return this._httpClient.put<void>(
			`${environment.apiUrl}/${UserEndpoint.Users}/${UserEndpoint.Update}`,
			undefined
		);
	}

	public deleteUser(userId: string): Observable<void> {
		const url = `${environment.apiUrl}/${UserEndpoint.Users}/${userId}/${UserEndpoint.Delete}`;
		return this._httpClient.delete<void>(url);
	}

	public getUserBalance() {
		return this._httpClient.get<UserBalance>(
			`${environment.apiUrl}/${UserEndpoint.Contracts}/${UserEndpoint.Users}/${UserEndpoint.Balance}`
		);
	}

	public getUserBalanceByUserId(userId: string) {
		return this._httpClient.get<UserBalance>(
			`${environment.apiUrl}/${UserEndpoint.Contracts}/${UserEndpoint.Users}/${UserEndpoint.Balance}/${userId}`
		);
	}
}

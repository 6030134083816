import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthConfig, NullValidationHandler, OAuthErrorEvent, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, filter, tap } from 'rxjs';
import { AuthCodeFlowConfig } from '../../constants/oauth/oauth.constants';
import { LoginConfig, OAuthModel, UserInfo } from '../../model/login/login.model';
import { isNotNull } from '../../utils/general-functions';
import { ConfigService } from '../template/config.service';

const RETRIEVE_PATH = 'retrievePath';
@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class OAuthLibService implements OnDestroy {
    public loginConfig!: LoginConfig;

    private _oauth$: BehaviorSubject<OAuthModel> = new BehaviorSubject<OAuthModel>({
        isLogged: false,
    });

    public oauth$ = this._oauth$.asObservable().pipe(untilDestroyed(this), tap(this.redirectToLoginPage.bind(this)));
    public locationPath: string[] = ['home'];

    constructor(
        private oauthService: OAuthService,
        configService: ConfigService,
        private router: Router,
        private activateRoute: ActivatedRoute,
        private location: Location
    ) {
        const retrievePath = localStorage.getItem(RETRIEVE_PATH);
        if (retrievePath && !retrievePath?.includes('login') && !retrievePath?.includes('logout')) {
            this.locationPath = retrievePath.split('/').filter(Boolean);
        }
        if (configService.loginConfig) this.loginConfig = configService.loginConfig;
        this.retrieveSession();
    }

    public ngOnDestroy(): void {
        this._oauth$.complete();
    }

    public setOauth(oauth: OAuthModel): void {
        this._oauth$.next(oauth);
    }
    public retrieveSession(): void {
        if (this.oauthService.hasValidAccessToken()) {
            const oauthModel = this.getOAuthModel(true);
            return this.setOauth(oauthModel);
        }
        this.oauthHandleErrors();
        this.setOAuthConfig();
        this.retrieveSessionExpired();
    }

    public isLogged(): boolean {
        return this._oauth$.getValue().isLogged;
    }

    public getUserInfo(): UserInfo | undefined {
        return this._oauth$.getValue().userInfo;
    }

    public login(): void {
        this.oauthService.loadDiscoveryDocumentAndTryLogin();
        this.oauthService.events.pipe(untilDestroyed(this)).subscribe((event: OAuthEvent) => {
            console.log(`Event received: ${event.type}`, event);
            if (event.type === 'token_expires') {
                console.log('Dbería cerrar');
                this.redirectLogOut();
            }
        });
        this.oauthService.initLoginFlow();
    }

    public getAccessTokenExpiration(): number {
        return this.oauthService.getAccessTokenExpiration();
    }

    public redirectLogOut(): void {
        this.logout();
        this.router.navigate(['logout']);
    }

    public logout(): void {
        const oauthModel = this.getOAuthModel(false);
        this._oauth$.next(oauthModel);
    }

    public getLoginTime(): Date | null {
        const userInfo = this.getUserInfo();
        if (userInfo) {
            return new Date(this.oauthService.getAccessTokenExpiration());
        }
        return null;
    }
    public callback(): void {
        this.oauthService
            .tryLogin()
            // .then(() => this.oauthService.tryLogin())
            .then(this.silentLogin.bind(this))
            .then(() => {
                console.log('User logged');
                const oauthModel = this.getOAuthModel(true);
                this._oauth$.next(oauthModel);
                this.redirectLogIn();
            })
            .catch(() => {
                console.log('Error in login');
            });
    }

    public getToken() {
        return this.oauthService.getAccessToken();
    }

    private retrieveSessionExpired(): void {
        const locationPath = this.location.path();
        if (!locationPath.includes('login')) localStorage.setItem('retrievePath', locationPath);
    }

    private redirectLogIn(): void {
        this.router.navigate([], {
            relativeTo: this.activateRoute,
            queryParams: null,
            replaceUrl: true,
        });
        this.router.navigate(this.locationPath, { replaceUrl: true });
    }

    private silentLogin(): Promise<any> {
        if (this.oauthService.hasValidAccessToken()) {
            return Promise.resolve();
        }
        return this.oauthService
            .silentRefresh()
            .then(() => {
                const oauthModel = this.getOAuthModel(true);
                this._oauth$.next(oauthModel);
                return Promise.resolve();
            })
            .catch(result => {
                if (result?.reason) {
                    console.error('User interaction is needed to log in, we will wait for the user to manually log in.');
                    return Promise.resolve();
                }
                return Promise.reject(result);
            });
    }

    private setOAuthConfig(): void {
        const {
            oauthUrl: issuer,
            uriCallback: redirectUri,
            clientId,
            scope,
            revocationEndpoint,
            logoutUrl,
            requireHttps,
            tokenEndpoint,
        } = this.loginConfig;
        const oauthConfig: AuthConfig = {
            ...AuthCodeFlowConfig,
            issuer,
            redirectUri,
            clientId,
            scope,
            revocationEndpoint,
            logoutUrl,
            postLogoutRedirectUri: logoutUrl,
            requireHttps,
            tokenEndpoint,
        };
        this.oauthService.configure(oauthConfig);
        this.oauthService.tokenValidationHandler = new NullValidationHandler();
        this.oauthService.setupAutomaticSilentRefresh();
    }

    private oauthHandleErrors(): void {
        this.oauthService.events
            .pipe(
                untilDestroyed(this),
                filter(event => event instanceof OAuthErrorEvent)
            )
            .subscribe(event => console.error('OAuthErrorEvent Object:', event));
    }

    private getOAuthModel(isLogged: boolean): OAuthModel {
        const userInfo = this.oauthService.getIdentityClaims() as UserInfo;
        return {
            isLogged,
            userInfo,
            accessToken: this.oauthService.getAccessToken(),
            refreshToken: this.oauthService.getRefreshToken(),
        };
    }

    private redirectToLoginPage(): void {
        const { isLogged, userInfo } = this._oauth$.getValue();
        const isLoggedOff = !isNotNull(isLogged, userInfo);
        const locationPath = this.location.path();
        if (isLoggedOff && !locationPath.includes('login')) this.router.navigate(['login'], { replaceUrl: true });
    }
}
